import VueI18n from 'vue-i18n';

export class I18n extends VueI18n {
  constructor(options) {
    options.messages = {};

    for (const locale of options.locales) {
      options.messages[locale.code] = locale.messages;
    }

    super(options);

    this.locales = options.locales;

    this.afterCallbacks = new Set();
    this.beforeCallbacks = new Set();

    if (options.hasOwnProperty('onInitialized')) {
      options.onInitialized.call(this);
    }
  }

  addListener(callback, before = false) {
    if (before) {
      this.beforeCallbacks.add(callback);
    } else {
      this.afterCallbacks.add(callback);
    }
  }

  removeListener(callback, before = false) {
    if (before) {
      this.beforeCallbacks.delete(callback);
    } else {
      this.afterCallbacks.delete(callback);
    }
  }

  async changeLocale(locale) {
    const oldLocale = this.locale;

    let callbacks = [];

    for (const callback of this.beforeCallbacks) {
      callbacks.push(callback(locale, oldLocale));
    }

    await Promise.all(callbacks);

    this.locale = locale;

    callbacks = [];

    for (const callback of this.afterCallbacks) {
      callbacks.push(callback(locale, oldLocale));
    }

    await Promise.all(callbacks);
  }
}
