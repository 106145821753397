import { render, staticRenderFns } from "./ask-for-password.vue?vue&type=template&id=ed1bd05c&"
import script from "./ask-for-password.vue?vue&type=script&lang=js&"
export * from "./ask-for-password.vue?vue&type=script&lang=js&"
import style0 from "./ask-for-password.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/.pnpm/vue-loader@15.9.6_0155b17f9cd7b4deb87a07278d29cc29/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports