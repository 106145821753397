<template>
  <div
    class="ask-for-password"
    @keyup.enter="done(true)"
  >
    <el-dialog
      :close-on-click-modal="false"
      :show-close="false"
      :visible="visible"
      center
      destroy-on-close
      width="350px"
      @closed="$emit('done', proceed ? model.password : false)"
    >
      <div class="d-flex justify-center">
        <el-avatar
          :size="100"
          :src="user.picture"
          icon="el-icon-fa-user fad"
        />
      </div>
      <p class="text-center">
        {{ user.name }}
      </p>

      <el-divider/>

      <el-form
        ref="form"
        :model="model"
        @submit.prevent="done(true)"
      >
        <el-form-item
          :show-message="false"
          prop="username"
          required
        >
          <el-input
            :value="username"
            disabled
            prefix-icon="el-icon-user"
          />
        </el-form-item>

        <el-form-item
          :show-message="false"
          prop="password"
          required
        >
          <el-input
            ref="passField"
            v-model="model.password"
            :placeholder="placeholder"
            prefix-icon="el-icon-key"
            show-password
          />
        </el-form-item>

        <div class="buttons">
          <el-button
            :type="cancelType"
            plain
            @click="done(false)"
            :disabled="loading"
          >
            {{
              cancelText ||
                'Cancel'
            }}
          </el-button>
          <el-button
            :icon="loading?'el-icon-loading':''"
            :type="okType"
            @click="done(true)"
            :disabled="loading"
          >
            {{ okText }}
          </el-button>
        </div>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
export default {
  props: ['user', 'placeholder', 'cancelText', 'okText', 'cancelType', 'okType', 'login'],

  data() {
    return {
      visible: true,
      model: {
        password: '',
      },
      proceed: true,
      loading: false,
    };
  },

  computed: {
    username() {
      const { user } = this.$props;

      if (user.type === 'INSTITUTE') {
        return user.mobile || user.email || user.username;
      }

      return user.username;
    },
  },

  methods: {
    async done(proceed) {
      if (proceed && !this.model.password) {
        try {
          await this.$refs.form.validate();
        } catch (e) {
          return;
        }
      }

      this.proceed = proceed;

      const {
        login,
        user,
      } = this.$props;

      if (!login || !proceed) {
        this.visible = false;
        return;
      }

      this.loading = true;

      const token = await this.$auth.login(user.username, this.model.password);

      if (!token) {
        this.loading = false;
        return this.$emit('login-failed');
      }

      this.visible = false;
    },
  },

  mounted() {
    this.$nextTick(() => {
      this.$refs.passField.focus();
    });
  },
};
</script>

<style lang="scss">
.ask-for-password {
  .el-dialog__wrapper {
    display: grid;
    align-items: center;
    justify-items: center;
  }

  .el-dialog {
    margin: unset !important;
  }

  .el-dialog__header {
    padding: 0;
  }

  p {
    font-size: 1.3em;
  }

  .buttons {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
}
</style>
