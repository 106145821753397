import MessageTypes from 'subscriptions-transport-ws/dist/message-types';
import { SubscriptionClient } from 'subscriptions-transport-ws';
import { emitter } from '@/plugins/emitter/emitter';

let csrfToken;

export const wsClient = new SubscriptionClient(`wss://${window.location.host}/v1/graphql`, {
  reconnect: true,
  lazy: true,
  connectionParams: async () => {
    if (!csrfToken) {
      return {};
    }

    return {
      headers: {
        'x-csrf-token': csrfToken,
      },
    };
  },
});

function restartWs() {
  // Restart subscription connection
  wsClient.close(true, true);

  wsClient.connect();

  // Re-register all subscriptions.
  Object.keys(wsClient.operations)
    .forEach((id) => {
      wsClient.sendMessage(id, MessageTypes.GQL_START, wsClient.operations[id].options);
    });
}

emitter.on('auth-login', (response) => {
  if (response.csrfToken !== csrfToken) {
    restartWs();
    csrfToken = response.csrfToken;
  }
});

emitter.on('auth-logout', () => {
  wsClient.close(true, true);
});
