import { InMemoryCache } from 'apollo-cache-inmemory';
import { getMainDefinition } from 'apollo-utilities';
import { emitter } from '@/plugins/emitter/emitter';
import { createHttpLink } from 'apollo-link-http';
import { setContext } from 'apollo-link-context';
import { wsClient } from '@/bootstrap/ws-client';
import { WebSocketLink } from 'apollo-link-ws';
import { RetryLink } from 'apollo-link-retry';
import { ApolloClient } from 'apollo-client';
import { onError } from 'apollo-link-error';
import { split } from 'apollo-link';
import VueApollo from 'vue-apollo';

let csrfToken;

const url = new URL(`${window.location.protocol}//${window.location.host}/v1/graphql`);

emitter.on('auth-login', (response) => {
  if (response.csrfToken !== csrfToken) {
    csrfToken = response.csrfToken;
  }
});

/* ---------------------------- Links -------------------------- */

url.protocol = 'https:';

const httpLink = createHttpLink({
  uri: url.toString(),
});

const headerLink = setContext(async (_, { headers }) => {
  const ctx = {
    headers,
  };

  if (!headers) {
    ctx.headers = {};
  }

  if (csrfToken) {
    ctx.headers['x-csrf-token'] = csrfToken;
  }

  return ctx;
});

const errorMessageLink = onError(({
  networkError,
  graphQLErrors,
}) => {
  if (networkError) {
    if (!window.navigator.onLine) {
      emitter.emit('$error-message', 'NETWORK_ERROR');
    } else if (networkError.extensions.code === 'validation-failed') {
      emitter.emit('$error-message', 'UNEXPECTED');
    }
  } else if (graphQLErrors) {
    for (let i = 0; i < graphQLErrors.length; i += 1) {
      const err = graphQLErrors[i];

      emitter.emit('$error-message', err.extensions.code);
    }
  }
});

const retryLink = new RetryLink({
  attempts: (count, operation, error) => !!error,
  delay: (count) => count * 1000 * Math.random(),
});
/* -------------------------- End Links ----------------------- */

// using the ability to split links, you can send data to each link
// depending on what kind of operation is being sent
const splitter = ({ query }) => {
  const definition = getMainDefinition(query);
  return definition.kind === 'OperationDefinition'
    && definition.operation === 'subscription';
};

const cache = new InMemoryCache();

// Create the apollo client
export const apolloClient = new ApolloClient({
  link: split(
    splitter,
    errorMessageLink.concat(new WebSocketLink(wsClient)),
    headerLink
      .concat(errorMessageLink)
      .concat(retryLink)
      .concat(httpLink),
  ),
  cache,
  connectToDevTools: true,
});

export const apolloProvider = new VueApollo({
  defaultClient: apolloClient,
});
