import { apolloClient } from '@/bootstrap/apollo-client';
import { BroadcastChannel } from 'broadcast-channel';
import { emitter } from '@/plugins/emitter/emitter';
import GET_USER from './graphql/get-user.graphql';
import LOGOUT from './graphql/logout.graphql';
import LOGIN from './graphql/login.graphql';

const RESPONSE_KEY = 'auth-response';

export default class Auth {
  constructor() {
    let response;

    const sessionToken = sessionStorage.getItem(RESPONSE_KEY);
    const localToken = localStorage.getItem(RESPONSE_KEY);

    if (sessionToken) {
      response = sessionToken;
    } else {
      response = localToken;
      this.rememberMe = true;
    }

    if (response) {
      this.response = JSON.parse(response);
    }

    this.channel.onmessage = this.handleBroadcast.bind(this);
  }

  channel = new BroadcastChannel('auth-manager');

  initialized = false;

  response = null;

  rememberMe = false;

  async initialize() {
    let user = null;

    if (!this.response) {
      await this.channel.postMessage({
        type: 'ask-response',
      });
    } else {
      try {
        const result = await apolloClient.query({
          query: GET_USER,
          variables: {
            id: this.response.id,
          },
          context: {
            headers: {
              'x-csrf-token': this.response.csrfToken,
            },
          },
        });

        user = result.data.User_by_pk;
      } catch (e) {
        Auth.cleanStorages();
      }
    }

    if (user) {
      emitter.emit('auth-login', this.response);
    }

    this.initialized = true;
    emitter.emit('auth-init', user);

    return user;
  }

  saveResponse(response, rememberMe) {
    (rememberMe ? localStorage : sessionStorage)
      .setItem(RESPONSE_KEY, JSON.stringify(response));

    this.response = response;
    this.rememberMe = rememberMe;
  }

  async handleBroadcast(message) {
    if (!message.type) {
      return;
    }

    switch (message.type) {
      case 'response':
        if (!this.response || message.response.csrfToken !== this.response.csrfToken) {
          this.saveResponse(message.response, message.rememberMe);
          await this.initialize();
        }
        break;
      case 'logout':
        await this.logout(false);
        break;
      case 'ask-response':
        if (this.response) {
          await this.channel.postMessage({
            type: 'response',
            response: this.response,
            rememberMe: this.rememberMe,
          });
        }
        break;
      default:
        console.warn(`Invalid message type ${typeof message.type}`);
    }
  }

  async getToken() {
    return this.response?.csrfToken;
  }

  async login(username, password, rememberMe = this.rememberMe) {
    let result;

    try {
      result = await apolloClient.mutate({
        mutation: LOGIN,
        variables: {
          payload: {
            username,
            password,
            rememberMe,
          },
        },
      });
    } catch (e) {
      return false;
    }

    emitter.emit('auth-login', result.data.auth_login);

    this.saveResponse(result.data.auth_login, rememberMe);

    await this.channel.postMessage({
      type: 'response',
      response: this.response,
      rememberMe: this.rememberMe,
    });

    await this.initialize();

    return true;
  }

  async logout(mutate = true) {
    if (mutate) {
      try {
        await apolloClient.mutate({
          mutation: LOGOUT,
        });
      } catch (e) {
        //
      }

      await this.channel.postMessage({
        type: 'logout',
      });
    }

    Auth.cleanStorages();
    this.response = null;

    emitter.emit('auth-logout');
  }

  static cleanStorages() {
    localStorage.removeItem(RESPONSE_KEY);
    sessionStorage.removeItem(RESPONSE_KEY);
  }
}
