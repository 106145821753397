import Vue from 'vue';
import AskForPassword from './ask-for-password';

function askForPassword(options) {
  const defaultOptions = {
    cancelType: 'plain',
    okType: 'primary',
    ...options,
  };

  const {
    cancelType,
    okType,
    cancelText,
    placeholder,
    okText,
    login,
    onOpen,
    loginFailedMsg,
  } = defaultOptions;

  return new Promise((resolve) => {
    const div = document.createElement('div');
    document.body.appendChild(div);

    const store = this.$store;

    const Component = Vue.extend(AskForPassword);
    const instance = new Component({
      propsData: {
        user: store.state.auth.user,
        cancelText: cancelText || this.$t('common.cancel'),
        okText: okText || this.$t('common.proceed'),
        placeholder: placeholder || this.$t('input.password'),
        cancelType,
        okType,
        login,
      },
      mounted() {
        if (onOpen) {
          setTimeout(onOpen, 1000);
        }
      },
    });

    instance.$once('done', (passwordOrCanceled) => {
      resolve(passwordOrCanceled);

      instance.$destroy();

      instance.$el.parentElement.removeChild(instance.$el);
    });

    if (login) {
      instance.$on('login-failed', () => {
        this.$message({
          message: loginFailedMsg || this.$t('validation.pass.wrong'),
          type: 'error',
        });
      });
    }

    instance.$mount(div);
  });
}

export default {
  install() {
    Vue.prototype.$askForPassword = askForPassword;
  },
};
