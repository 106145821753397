const sizes = {
  sm: 768,
  md: 992,
  lg: 1200,
  xl: 1920,
};

export const style = {
  namespaced: true,

  state: {
    breakpoints: {
      'sm-and-down': {
        query: `(max-width: ${sizes.md - 1}px)`,
        matches: false,
      },

      'md-and-down': {
        query: `(max-width: ${sizes.lg - 1}px)`,
        matches: false,
      },

      'md-and-up': {
        query: `(min-width: ${sizes.md - 1}px)`,
        matches: false,
      },

      'lg-and-up': {
        query: `(min-width: ${sizes.lg - 1}px)`,
        matches: false,
      },
    },
    asideWidth: 0,
    formWidth: 0,
  },

  mutations: {
    updateAsideWidth(state, value) {
      state.asideWidth = value;
    },
    updateFormWidth(state, value) {
      state.formWidth = value;
    },
  },

  actions: {
    initialize({ state }) {
      const { breakpoints } = state;

      for (const key in breakpoints) {
        if (!breakpoints.hasOwnProperty(key)) {
          continue;
        }

        const breakpoint = breakpoints[key];

        const mediaQueryList = window.matchMedia(breakpoint.query);

        breakpoint.matches = mediaQueryList.matches;

        mediaQueryList.addEventListener('change', (mql) => {
          breakpoint.matches = mql.matches;
        });
      }
    },
  },
};
