/* eslint-disable no-underscore-dangle */

async function dispatch(module, action) {
  const { _rawModule } = module;

  if (_rawModule.actions !== undefined && _rawModule.actions[action] !== undefined) {
    await module.context.dispatch(action);
  }
}

function resolve(path, obj) {
  // "*" means the whole state of the module
  if (path === '*') {
    return obj;
  }

  // Example value: header.menu.name
  const parts = path.split('.');

  // Pick the first child in the path
  let value = obj[parts[0]];

  // Remove the first child then iterate over all the children, and pick one by one
  // After the iteration, the only child left will be returned

  parts.shift();
  parts.forEach((index) => {
    value = parts[index];
  });

  return value;
}

function watch(module, store) {
  const { _rawModule } = module;

  // watch is optional
  if (!_rawModule.hasOwnProperty('watch')) {
    return;
  }

  // Get the watch object of store
  const watchers = _rawModule.watch;

  // Iterate over all the watcher and make them watch
  for (const path in watchers) {
    if (!watchers.hasOwnProperty(path)) {
      continue;
    }

    // Watcher can be an object or a function
    // for object, the schema should be something like:
    // { callback: Function, deep?: boolean, immediate?: boolean }

    // The callback will be called with:
    // commit, dispatch, state, rootState (in case of child module), unwatch

    // You should call unwatch to stop watching

    const watcher = watchers[path];
    const callback = typeof watcher === 'function' ? watcher : watcher.callback;
    const deep = watcher.hasOwnProperty('deep') ? watcher.deep : false;
    const immediate = watcher.hasOwnProperty('immediate') ? watcher.immediate : false;

    const unwatch = store.watch(() => resolve(path, _rawModule.state), (newValue, oldValue) => {
      const context = {
        ...module.context,
        state: module.state,
        unwatch: () => unwatch(),
      };

      // Add rootState only if it is a child module
      if (store.state !== module.state) {
        context.rootState = store.state;
      }

      callback(newValue, oldValue, context);
    }, {
      deep,
      immediate,
    });
  }
}

export async function initializeStore(store) {
  const { root } = store._modules;

  // Initialize watchers

  watch(root, store); // Main module

  Object.keys(root._children) // Child modules
    .map((key) => watch(root._children[key], store));

  // Initialize main store
  await dispatch(root, 'initialize');

  // Initialize store modules
  await Promise.all(
    Object.keys(root._children)
      .map(
        (key) => dispatch(root._children[key], 'initialize'),
      ),
  );

  // Trigger post-initialization actions

  await Promise.all(
    Object.keys(root._children)
      .map(
        (key) => dispatch(root._children[key], 'initialized'),
      ),
  );

  await dispatch(root, 'initialized');
}
