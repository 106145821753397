<template functional>
  <i :class="`el-icon-fa-${props.icon} ${props.type} ${data.staticClass || ''}`">
  </i>
</template>

<script>
export default {
  props: {
    icon: {
      type: String,
      required: true,
    },
    type: {
      type: String,
      default: 'fad',
    },
  },
};
</script>
